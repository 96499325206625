<template>
  <div
    v-if="show"
    class="list-summary-rekomendasi"
  >
    <template v-if="keterangan !== ''">
      <h4 class="mb-1 font-weight-normal">
        Rangkuman Hasil Pembahasan Peninjauan Kembali
      </h4>
      <p class="mb-2">
        {{ keterangan }}
      </p>
      <h5 class="my-1 border-top-secondary pt-2">
        Rekomendasi Hasil Pembahasan Peninjauan Kembali :
      </h5>
    </template>
    <template v-if="listRekapLanjut.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Dilanjutkan
        <template
          v-if="userLanjut.length !== 0"
        >
          <b-badge
            v-for="(people, keyd) in userLanjut"
            :key="keyd"
            v-b-tooltip.hover
            variant="light-info"
            class="ml-25"
            size="sm"
            :title="people"
          >
            {{ findInitials(people) }}
          </b-badge>
        </template>
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in listRekapLanjut"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            v-if="data.author && userData.group_id < 99"
            style="width:120px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>

    <template v-if="listRekapTidak.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Tidak Dilanjutkan
        <template
          v-if="userTidak.length !== 0"
        >
          <b-badge
            v-for="(people, keyd) in userTidak"
            :key="keyd"
            v-b-tooltip.hover
            variant="light-info"
            class="ml-25"
            size="sm"
            :title="people"
          >
            {{ findInitials(people) }}
          </b-badge>
        </template>
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in listRekapTidak"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            v-if="data.author && userData.group_id < 99"
            style="width:140px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>

    <template v-if="document !== ''">
      <h4 class="mb-1 font-weight-normal">
        Dokumen Pendukung
      </h4>
      <p class="mb-1">
        <b-button
          variant="success"
          size="sm"
          class="mr-25 mb-25"
          @click="openKomponen(document)"
        >
          <feather-icon
            icon="BookOpenIcon"
            size="14"
          /></b-button>
        <b-link
          :href="getDoc(document)"
          class="btn btn-sm btn-info mr-25 mb-25"
          target="_blank"
        >
          <feather-icon
            icon="DownloadIcon"
            size="14"
          /></b-link>
      </p>
    </template>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, VBTooltip, BButton, BLink,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    keterangan: {
      type: String,
      default: '',
    },
    document: {
      type: String,
      default: '',
    },
    rekapLanjut: {
      type: Array,
      default: () => [],
    },
    rekapTidak: {
      type: Array,
      default: () => [],
    },
    userLanjut: {
      type: Array,
      default: () => [],
    },
    userTidak: {
      type: Array,
      default: () => [],
    },
    rekapLainnya: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      userData: getUserData(),
      listRekapLanjut: [],
      listRekapTidak: [],
    }
  },
  watch: {
    rekapLanjut() {
      this.remapReko()
    },
    rekapTidak() {
      this.remapReko()
    },
  },
  mounted() {
    this.remapReko()
  },
  methods: {
    openKomponen(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    findInitials(input) {
      return input.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('')
    },
    remapReko() {
      if (this.rekapLanjut) {
        const keys = []
        this.listRekapLanjut = []
        this.rekapLanjut.map(value => {
          if (keys.indexOf(value.recommendation) === -1) {
            keys.push(value.recommendation)
            const reko = {
              recommendation: value.recommendation,
              author: [value.author],
            }
            this.listRekapLanjut.push(reko)
          }
          const lrlAuth = this.listRekapLanjut[keys.indexOf(value.recommendation)].author

          if (lrlAuth.indexOf(value.author) === -1) {
            this.listRekapLanjut[keys.indexOf(value.recommendation)].author.push(value.author)
          }
          return true
        })
        this.show = true
      }
      if (this.rekapTidak) {
        const keys = []
        this.listRekapTidak = []
        this.rekapTidak.map(value => {
          if (keys.indexOf(value.recommendation) === -1) {
            keys.push(value.recommendation)
            const reko = {
              recommendation: value.recommendation,
              author: [value.author],
            }
            this.listRekapTidak.push(reko)
          }
          const lrlAuth = this.listRekapTidak[keys.indexOf(value.recommendation)].author

          if (lrlAuth.indexOf(value.author) === -1) {
            this.listRekapTidak[keys.indexOf(value.recommendation)].author.push(value.author)
          }
          return true
        })
        this.show = true
      }
    },
  },
}
</script>
